import React from "react"
import SEO from "../components/seo"
import "./index.scss"

const Circle = (props: { levels: number }) => {
  if (!props.levels) {
    return <></>
  }
  return (
    <div className="circle">
      <Circle levels={props.levels - 1}></Circle>
    </div>
  )
}

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <div className="circle wrap">
      <Circle levels={14}></Circle>
    </div>
  </>
)

export default IndexPage
